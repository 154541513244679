<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div class="title font-weight-bold">CGU</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <!--iframe src="https://roote.io/pdf/CGU.pdf" width="400" height="500"/-->
        <div class="headline">1. Objet</div>

        <p>Les présentes conditions générales ont pour objet de définir les modalités et conditions
        d’utilisation des services proposés sur le site (ci-après : les « Services »), ainsi que de
          définir les droits et obligations des parties dans ce cadre.</p>

        <p>Elles sont notamment accessibles et imprimables à tout moment par un lien direct en bas
          de la page d’accueil du site.</p>

        <p>Elles peuvent être complétées, le cas échéant, par des conditions d’utilisation
        particulières à certains Services. En cas de contradiction, les conditions particulières
          prévalent sur ces conditions générales.</p>

        <div class="headline">2. Exploitant des Services</div>
        <p>Les Services sont exploités par la société Roote, SAS au capital social de 3000 euros,
        immatriculée au RCS de Paris sous le n° 84100566300015, dont le siège social est situé
          au 128 Rue la Boétie, 75008 Paris (ci-après : « Roote »).</p>

        <p>Roote peut être contactée aux coordonnées suivantes :</p>
        <ul>
          <li>Adresse postale : 128 Rue la Boétie, 75008 Paris</li>
          <li>Adresse électronique : contact@roote.io</li>
        </ul>

        <div class="headline">3. Accès au site et aux Services</div>

        <p>Les Services sont accessibles, sous réserve des restrictions prévues sur le site :</p>

        <ul>
          <li>à toute personne physique disposant de la pleine capacité juridique pour
        s’engager au titre des présentes conditions générales. La personne
        physique qui ne dispose pas de la pleine capacité juridique ne peut accéder
            au Site et aux Services qu’avec l’accord de son représentant légal ;</li>

          <li>à toute personne morale agissant par l’intermédiaire d’une personne
        physique disposant de la capacité juridique pour contracter au nom et pour
            le compte de la personne morale.</li>
        </ul>

        <div class="headline">4. Acceptation des conditions générales</div>
        <p>L’acceptation des présentes conditions générales est matérialisée par une case à cocher
          dans le formulaire d’inscription. Cette acceptation ne peut être que pleine et entière.</p>

        <p>Toute adhésion sous réserve est considérée comme nulle et non avenue. L’Utilisateur qui
        n’accepte pas d’être lié par les présentes conditions générales ne doit pas utiliser les
          Services.</p>

        <div class="headline">5. Inscription sur le site</div>

        <p>5.1. L’utilisation des Services nécessite que l’Utilisateur s’inscrive sur le site, en
        remplissant le formulaire prévu à cet effet. L’Utilisateur doit fournir l’ensemble des
        informations marquées comme obligatoires. Toute inscription incomplète ne sera
          pas validée.
        L’inscription entraîne automatiquement l’ouverture d’un compte au nom de
        l’Utilisateur (ci-après : le « Compte »), lui donnant accès à un espace personnel
        (ci-après : l’« Espace Personnel ») qui lui permet de gérer son utilisation des
        Services sous une forme et selon les moyens techniques que Roote juge les plus
        appropriés pour rendre lesdits Services.
        L’Utilisateur garantit que toutes les informations qu’il donne dans le formulaire
        d’inscription sont exactes, à jour et sincères et ne sont entachées d’aucun
        caractère trompeur.
        Il s’engage à mettre à jour ces informations dans son Espace Personnel en cas de
        modifications, afin qu’elles correspondent toujours aux critères susvisés.
        L’Utilisateur est informé et accepte que les informations saisies aux fins de création
        ou de mise à jour de son Compte vaillent preuve de son identité. Les informations
          saisies par l’Utilisateur l’engagent dès leur validation.</p>

        <p>5.2. L’Utilisateur peut accéder à tout moment à son Espace Personnel après s’être
        identifié à l’aide de son identifiant de connexion ainsi que de son mot de passe.
        L’Utilisateur s’engage à utiliser personnellement les Services et à ne permettre à
        aucun tiers de les utiliser à sa place ou pour son compte, sauf à en supporter
        l’entière responsabilité.
        Il est pareillement responsable du maintien de la confidentialité de son identifiant
        et de son mot de passe. Il doit immédiatement contacter Roote aux coordonnées
        mentionnées à l’article 2 des présentes s’il remarque que son Compte a été utilisé
        à son insu. Il reconnaît à Roote le droit de prendre toutes mesures appropriées en
          pareil cas.</p>

        <div class="headline">6. Description des Services</div>
        <p>L’Utilisateur a accès aux Services décrits sur le site, sous une forme et selon les
          fonctionnalités et moyens techniques que Roote juge les plus appropriés.</p>

        <div class="headline">7. Services gratuits ET payants</div>
        <p>Certains Services sont fournis à titre gratuit, notamment l’information voyageur.</p>

        <p class="font-weight-bold">7.1. Prix</p>

        <p>Le prix des Services est indiqué sur le site.
        Sauf mention contraire, ils sont exprimés en euros et toutes taxes françaises
        comprises.

        Roote se réserve le droit, à sa libre discrétion et selon des modalités dont elle sera
          seule juge, de proposer des offres promotionnelles ou réductions de prix.</p>

        <p class="font-weight-bold">7.2. Révision des prix</p>

        <p>Le prix de Services peut faire l’objet d’une révision par Roote à tout moment, à sa
        libre discrétion.
        L’Utilisateur sera informé de ces modifications par Roote par email sous un préavis
        de 2 mois au moins avant l’entrée en vigueur des nouveaux tarifs.
        L’Utilisateur qui n’accepte pas les nouveaux prix doit mettre fin à son utilisation
        des Services selon les modalités prévues à l’article 18. A défaut, il sera réputé
          avoir accepté les nouveaux tarifs.</p>

        <p class="font-weight-bold">7.3. Facturation</p>
        <p>Les Services font l’objet de factures qui sont communiquées à l’Utilisateur par tout
          moyen utile.</p>

        <p class="font-weight-bold">7.4. Modalités de paiement</p>
        <p>
        Les modalités de paiement du prix des Services sont décrites sur le site.
        Le paiement s’effectue par prélèvement automatique à partir du numéro de carte
          bancaire de l’Utilisateur.</p>
        <p>
        Le prélèvement est mis en œuvre par le prestataire de paiement désigné sur le
        site, qui seul conserve les coordonnées bancaires de l’Utilisateur à cette fin. Roote
          ne conserve aucune coordonnée bancaire.</p>
        <p>L’Utilisateur garantit à Roote qu'il dispose des autorisations nécessaires pour
        utiliser le mode de paiement choisi. Il s’engage à prendre les mesures nécessaires
          afin que le prélèvement automatique du prix des Services puisse être effectué.</p>

        <p class="font-weight-bold">7.5. Retards et incidents de paiement</p>
        <p>L’Utilisateur est informé et accepte expressément que tout retard de paiement de
        tout ou partie d’une somme due à son échéance entraînera automatiquement,
          sans préjudice des dispositions de l’article 12 et sans mise en demeure préalable :</p>
        <ol>
          <li>la déchéance du terme de l’ensemble des sommes dues par l’Utilisateur et
            leur exigibilité immédiate ;</li>
          <li>la suspension immédiate des Services en cours jusqu’au complet paiement
            de l’intégralité des sommes dues par l’Utilisateur ;</li>
          <li>la facturation au profit de Roote d’un intérêt de retard au taux de 3x le taux
            de l’intérêt légal, assis sur le montant de l’intégralité des sommes dues par
            l’Utilisateur.</li>
        </ol>

        <div class="headline">8. Données</div>
        <p>L’Utilisateur reconnaît et accepte expressément :</p>
        <ol>
          <li>que les données recueillies sur le site et sur les équipements informatiques de
            Roote font foi de la réalité des opérations intervenues dans le cadre des présentes ;</li>
          <li>que ces données constituent le seul mode de preuve admis entre les parties,
            notamment pour le calcul des sommes dues à Roote.</li>
        </ol>
        <p>L’Utilisateur peut accéder à ces données dans son Espace Personnel.</p>

        <div class="headline">9. Obligations de l’Utilisateur</div>

        <p>Sans préjudice des autres obligations prévues aux présentes, l’Utilisateur s’engage à
          respecter les obligations qui suivent :</p>

        <p>9.1 L’Utilisateur s’engage, dans son usage des Services, à respecter les lois et
        règlements en vigueur et à ne pas porter atteinte aux droits de tiers ou à l’ordre
        public.
        Il est notamment seul responsable du bon accomplissement de toutes les
        formalités notamment administratives, fiscales et/ ou sociales et de tous les
        paiements de cotisations, taxes ou impôts de toutes natures qui lui incombent, le
        cas échéant, en relation avec son utilisation des Services. La responsabilité de
        Roote ne pourra en aucun cas être engagée à ce titre.</p>

        <p>9.2. L’Utilisateur reconnaît avoir pris connaissance sur le site des caractéristiques et
        contraintes, notamment techniques, de l’ensemble des Services. Il est seul
          responsable de son utilisation des Services.</p>

        <p>9.3. L’Utilisateur est informé et accepte que la mise en œuvre des Services nécessite
        qu’il soit connecté à internet et que la qualité des Services dépend directement de
          cette connexion, dont il est seul responsable.</p>

        <p>9.4. L’Utilisateur est également seul responsable des relations qu’il pourra nouer avec
        les autres Utilisateurs et des informations qu’il leur communique dans le cadre des
        Services. Il lui appartient d’exercer la prudence et le discernement appropriés
        dans ces relations et communications. L’Utilisateur s’engage en outre, dans ses
        échanges avec les autres Utilisateurs, à respecter les règles usuelles de politesse
          et de courtoisie.</p>

        <p>9.5. L’Utilisateur s’engage à faire un usage strictement personnel des Services. Il
        s’interdit en conséquence de céder, concéder ou transférer tout ou partie de ses
        droits ou obligations au titre des présentes à un tiers, de quelque manière que ce
          soit.</p>

        <p>9.6. L’Utilisateur s’engage à fournir à Roote toutes les informations nécessaires à la
        bonne exécution des Services. Plus généralement, l’Utilisateur s’engage à
        coopérer activement avec Roote en vue de la bonne exécution des présentes.</p>

        <p>9.7. L’Utilisateur est seul responsable des contenus de toute nature (rédactionnels,
        graphiques, audiovisuels ou autres, en ce compris la dénomination et/ou l’image
        éventuellement choisies par l’Utilisateur pour l’identifier sur le site) qu’il diffuse
        dans le cadre des Services (ci-après désignés : les « Contenus »).
        Il garantit à Roote qu’il dispose de tous les droits et autorisations nécessaires à la
        diffusion de ces Contenus.
        Il s’engage à ce que lesdits Contenus soient licites, ne portent pas atteinte à
        l’ordre public, aux bonnes mœurs ou aux droits de tiers, n’enfreignent aucune
        disposition législative ou règlementaire et plus généralement, ne soient
        aucunement susceptibles de mettre en jeu la responsabilité civile ou pénale de
          Roote.</p>

        <p>L’Utilisateur s’interdit ainsi de diffuser, notamment et sans que cette liste soit
          exhaustive :</p>
        <ul>

        <li>des Contenus pornographiques, obscènes, indécents, choquants ou inadaptés
        à un public familial, diffamatoires, injurieux, violents, racistes, xénophobes ou
          révisionnistes,</li>
          <li>des Contenus contrefaisants,</li>
          <li>des Contenus attentatoires à l’image d’un tiers,</li>
        <li>des Contenus mensongers, trompeurs ou proposant ou promouvant des
          activités illicites, frauduleuses ou trompeuses,</li>
        <li>des Contenus nuisibles aux systèmes informatiques de tiers (tels que virus,
          vers, chevaux de Troie, etc.),</li>
        <li>et plus généralement des Contenus susceptibles de porter atteinte aux droits
        de tiers ou d’être préjudiciables à des tiers, de quelque manière et sous
          quelque forme que ce soit.</li>
        </ul>

        <p>9.8. L’Utilisateur reconnaît que les Services lui offrent une solution supplémentaire
        mais non alternative des moyens qu’il utilise déjà par ailleurs pour atteindre le
          même objectif et que cette solution ne saurait se substituer à ces autres moyens.</p>

        <p>9.9. L’Utilisateur doit prendre les mesures nécessaires pour sauvegarder par ses
        propres moyens les informations de son Espace Personnel qu’il juge nécessaires,
          dont aucune copie ne lui sera fournie.</p>

        <p>9.10. L’Utilisateur est informé et accepte que la mise en œuvre des Services nécessite
        qu’il soit connecté à internet et que la qualité des Services dépend directement de
          cette connexion, dont il est seul responsable.</p>

        <div class="headline">10. Garantie de l’Utilisateur</div>

        <p>L’Utilisateur garantit Roote contre toutes plaintes, réclamations, actions et/ou
        revendications quelconques que Roote pourrait subir du fait de la violation, par
        l’Utilisateur de l’une quelconque de ses obligations ou garanties aux termes des
          présentes conditions générales.</p>
        <p>
        Il s’engage à indemniser Roote de tout préjudice qu’elle subirait et à lui payer tous les
          frais, charges et/ou condamnations qu’elle pourrait avoir à supporter de ce fait.</p>

        <div class="headline">11. Comportements prohibés</div>

        <p>11.1. Il est strictement interdit d’utiliser les Services aux fins suivantes :
          <ul>
        <li>l’exercice d’activités illégales, frauduleuses ou portant atteinte aux droits ou à la
          sécurité des tiers,</li>
        <li>l’atteinte à l’ordre public ou la violation des lois et règlements en vigueur,</li>
        <li>l’intrusion dans le système informatique d’un tiers ou toute activité de nature à
        nuire, contrôler, interférer, ou intercepter tout ou partie du système informatique
          d’un tiers, en violer l’intégrité ou la sécurité,</li>
        <li>l’envoi d’emails non sollicités et/ou de prospection ou sollicitation commerciale,</li>
        <li>les manipulations destinées à améliorer le référencement d’un site tiers,</li>
        <li>l’aide ou l’incitation, sous quelque forme et de quelque manière que ce soit, à
          un ou plusieurs des actes et activités décrits ci-dessus,</li>
        <li>et plus généralement toute pratique détournant les Services à des fins autres
          que celles pour lesquelles ils ont été conçus.</li>
      </ul>
        <p>11.2. Il est strictement interdit aux Utilisateurs de copier et/ou de détourner à leurs fins
        ou à celles de tiers le concept, les technologies ou tout autre élément du site de
          Roote.</p>
        <p>11.3. Sont également strictement interdits : (i) tous comportements de nature à
        interrompre, suspendre, ralentir ou empêcher la continuité des Services, (ii) toutes
        intrusions ou tentatives d’intrusions dans les systèmes de Roote, (iii) tous
        détournements des ressources système du site, (iv) toutes actions de nature à
        imposer une charge disproportionnée sur les infrastructures de ce dernier, (v)
        toutes atteintes aux mesures de sécurité et d’authentification, (vi) tous actes de
        nature à porter atteinte aux droits et intérêts financiers, commerciaux ou moraux
        de Roote ou des usagers de son site, et enfin plus généralement (vii) tout
          manquement aux présentes conditions générales.</p>
        <p>11.4. Il est strictement interdit de monnayer, vendre ou concéder tout ou partie de
        l’accès aux Services ou au site, ainsi qu’aux informations qui y sont hébergées et/
          ou partagées.</p>

        <div class="headline">12. Sanctions des manquements</div>

        <p>En cas de manquement à l’une quelconque des dispositions des présentes conditions
        générales ou plus généralement, d’infraction aux lois et règlements en vigueur par un
        Utilisateur, Roote se réserve le droit de prendre toute mesure appropriée et notamment
          de :</p>
        <ol>
          <li>suspendre ou résilier l’accès aux Services de l’Utilisateur, auteur du manquement ou
            de l’infraction, ou y ayant participé,</li>
          <li>supprimer tout contenu mis en ligne sur le site,</li>
          <li>publier sur le site tout message d’information que Roote jugera utile,</li>
          <li>avertir toute autorité concernée,</li>
          <li>engager toute action judiciaire.</li>
        </ol>

        <div class="headline mt-2">13. Responsabilité et garantie de Roote</div>
        <p>13.1. Roote s’engage à fournir les Services avec diligence et selon les règles de l’art,
        étant précisé qu’il pèse sur elle une obligation de moyens, à l’exclusion de toute
        obligation de résultat, ce que les Utilisateurs reconnaissent et acceptent
          expressément.</p>
        <p>13.2. Roote n’a pas connaissance des Contenus mis en ligne par les Utilisateurs dans le
        cadre des Services, sur lesquels elle n’effectue aucune modération, sélection,
        vérification ou contrôle d’aucune sorte et à l’égard desquels elle n’intervient qu’en
        tant que prestataire d’hébergement.
        En conséquence, Roote ne peut être tenue pour responsable des Contenus, dont
        les auteurs sont des tiers, toute réclamation éventuelle devant être dirigée en
        premier lieu vers l’auteur des Contenus en question.
        Les Contenus préjudiciables à un tiers peuvent faire l’objet d’une notification à
        Roote selon les modalités prévues par l’article 6 I 5 de la loi n° 2004-575 du 21
        juin 2004 pour la confiance dans l'économie numérique, Roote se réservant de
          prendre les mesures décrites à l’article 12.</p>
        <p>13.3. Roote décline toute responsabilité en cas de perte éventuelle des informations
        accessibles dans l’Espace Personnel de l’Utilisateur, celui-ci devant en
        sauvegarder une copie et ne pouvant prétendre à aucun dédommagement à ce
          titre.</p>
        <p>13.4. Roote s’engage à procéder régulièrement à des contrôles afin de vérifier le
        fonctionnement et l’accessibilité du site. A ce titre, Roote se réserve la faculté
        d’interrompre momentanément l’accès au site pour des raisons de maintenance.
        De même, Roote ne saurait être tenue responsable des difficultés ou impossibilités
        momentanées d’accès au site qui auraient pour origine des circonstances qui lui
        sont extérieures, la force majeure, ou encore qui seraient dues à des perturbations
          des réseaux de télécommunication.</p>
        <p>13.5. Roote ne garantit pas aux Utilisateurs (i) que les Services, soumis à une recherche
        constante pour en améliorer notamment la performance et le progrès, seront
        totalement exempts d’erreurs, de vices ou défauts, (ii) que les Services, étant
        standard et nullement proposés à la seule intention d’un Utilisateur donné en
        fonction de ses propres contraintes personnelles, répondront spécifiquement à ses
          besoins et attentes.</p>
        <p>13.6. En tout état de cause, la responsabilité susceptible d’être encourue par Roote au
        titre des présentes est expressément limitée aux seuls dommages directs avérés
          subis par l’Utilisateur.</p>

        <div class="headline">14. Propriété intellectuelle</div>
        <p>Les systèmes, logiciels, structures, infrastructures, bases de données et contenus de
        toute nature (textes, images, visuels, musiques, logos, marques, base de données, etc
        …) exploités par Roote au sein du site sont protégés par tous droits de propriété
        intellectuelle ou droits des producteurs de bases de données en vigueur. Tous
        désassemblages, décompilations, décryptages, extractions, réutilisations, copies et plus
        généralement, tous actes de reproduction, représentation, diffusion et utilisation de l’un
        quelconque de ces éléments, en tout ou partie, sans l’autorisation de Roote sont
          strictement interdits et pourront faire l’objet de poursuites judiciaires.</p>

        <div class="headline">15. Données à caractère personnel</div>

        <p>Roote pratique une politique de protection des données personnelles dont les
        caractéristiques sont explicitées dans le document intitulé « Charte de confidentialité »,
          dont l’Utilisateur est expressément invité à prendre connaissance sur le site.</p>

        <div class="headline">16. Publicité</div>

        <p>Roote se réserve la faculté d’insérer sur toute page du site et dans toute communication
        aux Utilisateurs tous messages publicitaires ou promotionnels sous une forme et dans
          des conditions dont Roote sera seule juge.</p>

        <div class="headline">17. Liens et sites tiers</div>
        <p>Roote ne pourra en aucun cas être tenue pour responsable de la disponibilité technique
        de sites internet ou d’applications mobiles exploités par des tiers (y compris ses
        éventuels partenaires) auxquels l’Utilisateur accéderait par l'intermédiaire du site.
        Roote n'endosse aucune responsabilité au titre des contenus, publicités, produits et/ou
        services disponibles sur de tels sites et applications mobiles tiers dont il est rappelé qu’ils
        sont régis par leurs propres conditions d’utilisation.
        Roote n'est pas non plus responsable des transactions intervenues entre l’Utilisateur et un
        quelconque annonceur, professionnel ou commerçant (y compris ses éventuels partenaires) vers
        lequel l’Utilisateur serait orienté par l'intermédiaire du site et ne saurait en aucun cas être partie à
        quelques litiges éventuels que ce soit avec ces tiers concernant notamment la livraison de
        produits et/ou services, les garanties, déclarations et autres obligations quelconques auxquelles
          ces tiers sont tenus.</p>

        <div class="headline">18. Durée des Services, désinscription</div>
        <p>Les Services sont souscrits pour une durée indéterminée.
        L’Utilisateur peut se désinscrire des Services à tout moment, en adressant une demande
        à cet effet à Roote par email, aux coordonnées mentionnées à l’article 2.
        La désinscription est effective immédiatement. Elle entraîne la suppression automatique
          du Compte de l’Utilisateur.</p>

        <div class="headline">19. Modifications</div>
        <p>Roote se réserve la faculté de modifier à tout moment les présentes conditions
          générales.</p>
        <p>L’Utilisateur sera informé de ces modifications par tout moyen utile.</p>
        <p>L’Utilisateur qui n’accepte pas les conditions générales modifiées doit se désinscrire des
          Services selon les modalités prévues à l’article 18.</p>
        <p>Tout Utilisateur qui a recours aux Services postérieurement à l’entrée en vigueur des
          conditions générales modifiées est réputé avoir accepté ces modifications.</p>

        <div class="headline">20. Langue</div>
        <p>Dans l’hypothèse d’une traduction des présentes conditions générales dans une ou
        plusieurs langues, la langue d’interprétation sera la langue française en cas de
        contradiction ou de contestation sur la signification d’un terme ou d’une disposition.</p>

        <div class="headline">21. Loi applicable et juridiction</div>
        <p>Les présentes conditions générales sont régies par la loi française.
        En cas de contestation sur la validité, l’interprétation et/ou l’exécution des présentes
        conditions générales, les parties conviennent que les tribunaux de Paris seront
        exclusivement compétents pour en juger, sauf règles de procédure impératives
          contraires.</p>

        <div class="headline">22. Entrée en vigueur</div>
        <p>Les présentes conditions générales sont entrées en vigueur le <span class="font-weight-bold">01/08/18</span></p>

      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
name: "GCU"
}
</script>

<style scoped>

</style>